<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Products' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field
                label="Code"
                :type="$store.getters.hasErrors('code').type"
                :message="$store.getters.hasErrors('code').message"
              >
                <b-input v-model="code" ref="code-input"></b-input>
              </b-field>
              <b-field
                label="Name"
                :type="$store.getters.hasErrors('name').type"
                :message="$store.getters.hasErrors('name').message"
              >
                <b-input v-model="name" ref="name-input"></b-input>
              </b-field>
              <Slugger
                :data="name"
                :saved_slug="savedSlug"
                table="products"
                column="slug"
                :errors="$store.getters.hasErrors('slug')"
                @on-slug-change="slug = $event"
              />
              <b-field label="Parent Product" v-if="notBase">
                <b-autocomplete
                  v-model="product_name"
                  :data="products"
                  placeholder="e.g. 2505-0001 or Santa Claus"
                  field="name"
                  :loading="isFetching"
                  :check-infinite-scroll="true"
                  @typing="getAsyncData"
                  @select="option => (selected = option)"
                  @infinite-scroll="getMoreAsyncData"
                  clearable
                >
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-left">
                        <img
                          width="50"
                          :src="
                            props.option.image
                              ? props.option.image.thumb
                              : $default_image_thumb
                          "
                        />
                      </div>
                      <div class="media-content">
                        {{ props.option.name }}
                        <br />
                        <small>
                          {{ props.option.code }}
                        </small>
                      </div>
                    </div>
                  </template>
                  <template slot="footer">
                    <span v-show="page > totalPages" class="has-text-grey">
                      No more products to show.
                    </span>
                  </template>
                </b-autocomplete>
              </b-field>
              <b-field
                v-if="
                  userHasPermission('write-prices') ||
                    $store.getters.userRole === 'super-admin'
                "
                label="Wholesale Price"
                :type="$store.getters.hasErrors('price_wholesale').type"
                :message="$store.getters.hasErrors('price_wholesale').message"
              >
                <b-input v-model="price_wholesale"></b-input>
              </b-field>
              <b-field
                v-if="
                  userHasPermission('write-prices') ||
                    $store.getters.userRole === 'super-admin'
                "
                label="Partner Price"
                :type="$store.getters.hasErrors('price_partner').type"
                :message="$store.getters.hasErrors('price_partner').message"
              >
                <b-input v-model="price_partner"></b-input>
              </b-field>
              <b-field
                v-if="
                  userHasPermission('write-prices') ||
                    $store.getters.userRole === 'super-admin'
                "
                label="Direct Sales Price"
                :type="$store.getters.hasErrors('price_direct_sales').type"
                :message="
                  $store.getters.hasErrors('price_direct_sales').message
                "
              >
                <b-input v-model="price_direct_sales"></b-input>
              </b-field>
            </div>
            <div class="p-4 border mt-4">
              <b-tabs>
                <b-tab-item label="Properties">
                  <b-field
                    label="Length"
                    :type="$store.getters.hasErrors('length').type"
                    :message="$store.getters.hasErrors('length').message"
                  >
                    <b-input v-model="length"></b-input>
                  </b-field>
                  <b-field
                    label="Width"
                    :type="$store.getters.hasErrors('width').type"
                    :message="$store.getters.hasErrors('width').message"
                  >
                    <b-input v-model="width"></b-input>
                  </b-field>
                  <b-field
                    label="Height"
                    :type="$store.getters.hasErrors('height').type"
                    :message="$store.getters.hasErrors('height').message"
                  >
                    <b-input v-model="height"></b-input>
                  </b-field>
                  <b-field
                    label="Packed Volume"
                    :type="$store.getters.hasErrors('volume_packed').type"
                    :message="$store.getters.hasErrors('volume_packed').message"
                  >
                    <b-input v-model="volume_packed"></b-input>
                  </b-field>
                  <b-field
                    label="Net Weight"
                    :type="$store.getters.hasErrors('weight_net').type"
                    :message="$store.getters.hasErrors('weight_net').message"
                  >
                    <b-input v-model="weight_net"></b-input>
                  </b-field>
                  <b-field
                    label="Gross Weight"
                    :type="$store.getters.hasErrors('weight_gross').type"
                    :message="$store.getters.hasErrors('weight_gross').message"
                  >
                    <b-input v-model="weight_gross"></b-input>
                  </b-field>
                  <b-field label="With Light">
                    <b-radio
                      v-model="is_illuminated"
                      :native-value="true"
                      type="is-success"
                      >Yes</b-radio
                    >
                    <b-radio
                      v-model="is_illuminated"
                      :native-value="false"
                      type="is-success"
                      >No</b-radio
                    >
                  </b-field>
                  <b-field
                    label="No. of Parts"
                    :type="$store.getters.hasErrors('parts').type"
                    :message="$store.getters.hasErrors('parts').message"
                  >
                    <b-numberinput
                      v-model="parts"
                      min="1"
                      step="1"
                      :editable="false"
                      type="is-success"
                      controls-position="compact"
                    ></b-numberinput>
                  </b-field>
                </b-tab-item>
                <b-tab-item label="Color References">
                  <ColorReference
                    v-for="(ref, index) in references"
                    :key="index"
                    class="mb-4"
                    :reference="ref"
                    :index="index"
                    :emit="`${index}-color-selected`"
                    @coordinates-updated="updateReference(index, $event)"
                    @remove-reference="removeReference($event)"
                    :edit="true"
                  />
                  <b-button @click="openFilesBrowser">Add Reference</b-button>
                </b-tab-item>
                <b-tab-item label="Costing">
                  coming soon
                </b-tab-item>
                <b-tab-item label="Materials">
                  coming soon
                </b-tab-item>
                <b-tab-item label="Instructions">
                  coming soon
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
          <div class="px-2 w-4/12">
            <FeaturedImage :image_id="image_id" type="image" />
            <FeaturedGallery
              title="Gallery"
              :image_ids="image_ids"
              type="image"
            />
            <div class="p-4 border mb-4">
              <b-field label="Categories"></b-field>
              <ul class="border rounded p-4 pb-2 h-64 overflow-y-scroll">
                <template v-for="mc in categories">
                  <li :key="mc.id">
                    <b-checkbox
                      type="is-success"
                      :native-value="mc.id"
                      v-model="category_ids"
                      @change.native="excludeChildren(mc.id, mc.children)"
                      >{{ mc.name }}</b-checkbox
                    >
                  </li>
                  <template v-if="mc.children.length">
                    <li v-for="sc in mc.children" :key="sc.id" class="ml-4">
                      <b-checkbox
                        type="is-success"
                        :native-value="sc.id"
                        v-model="category_ids"
                        @change.native="includeParent(sc.id, sc.category_id)"
                        >{{ sc.name }}</b-checkbox
                      >
                    </li>
                  </template>
                </template>
              </ul>
            </div>
            <div class="p-4 border">
              <div class="flex items-center justify-end">
                <b-button
                  class="mr-4"
                  v-if="$route.params.id"
                  type="is-danger is-light"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onDelete()"
                  >Delete</b-button
                >
                <b-button
                  type="is-success"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import Slugger from "@/components/admin/Slugger";
import FeaturedImage from "@/components/admin/FeaturedImage";
import FeaturedGallery from "@/components/admin/FeaturedGallery";
import ColorReference from "@/components/admin/ColorReference";
import store from "@/store";
import { Events } from "@/events.js";
export default {
  components: {
    Sidebar,
    Slugger,
    FeaturedImage,
    FeaturedGallery,
    ColorReference
  },
  data: () => ({
    name: "",
    slug: "",
    savedSlug: "",
    product_id: null,
    image_id: null,

    code: "2505-",
    price_wholesale: "",
    price_partner: "",
    price_direct_sales: "",
    length: "",
    width: "",
    height: "",
    volume_packed: "",
    parts: 1,
    weight_net: "",
    weight_gross: "",
    is_illuminated: false,

    image_ids: [],

    categories: [],
    category_ids: [],

    notBase: true,
    rals: [],
    ral_ids: [],

    products: [],
    selected: null,
    isFetching: false,
    product_name: "",
    saved_product_name: "",
    page: 1,
    totalPages: 1,

    references: []
  }),
  watch: {
    selected() {
      this.product_id = this.selected ? this.selected.id : null;
      this.price_wholesale = this.userHasPermission("write-prices")
        ? this.selected.price_wholesale
        : undefined;
      this.price_partner = this.userHasPermission("write-prices")
        ? this.selected.price_partner
        : undefined;
      this.length = this.selected.length;
      this.width = this.selected.width;
      this.height = this.selected.height;
      this.volume_packed = this.selected.volume_packed;
      this.parts = parseFloat(this.selected.parts);
      this.weight_net = this.selected.weight_net;
      this.weight_gross = this.selected.weight_gross;
      this.category_ids = _.map(this.selected.categories, "id");
    }
  },
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    onSave() {
      store.dispatch("saveProductsForm", {
        name: this.name,
        slug: this.slug,
        id: this.$route.params.id || false,
        image_id: this.image_id || null,
        product_id: this.product_id || null,

        code: this.code || null,
        price_wholesale:
          this.userHasPermission("write-prices") ||
          this.$store.userRole === "super-admin"
            ? this.price_wholesale
            : undefined,
        price_partner:
          this.userHasPermission("write-prices") ||
          this.$store.userRole === "super-admin"
            ? this.price_partner
            : undefined,
        price_direct_sales:
          this.userHasPermission("write-prices") ||
          this.$store.userRole === "super-admin"
            ? this.price_direct_sales
            : undefined,
        length: this.length,
        width: this.width,
        height: this.height,
        volume_packed: this.volume_packed,
        parts: this.parts,
        weight_net: this.weight_net,
        weight_gross: this.weight_gross,
        is_illuminated: this.is_illuminated,

        image_ids: this.image_ids,
        category_ids: this.category_ids,
        references: this.references
      });
    },
    onDelete() {
      store.dispatch("deleteSelection", {
        message: `Do you really want to delete this product?`,
        ids: [this.$route.params.id],
        path: "products",
        push: "Products"
      });
    },
    getAsyncData: _.debounce(function(product_name) {
      // String update
      if (this.saved_product_name !== product_name) {
        this.saved_product_name = product_name;
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
      }
      // String cleared
      if (!product_name.length) {
        this.products = [];
        this.page = 1;
        this.totalPages = 1;
        return;
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return;
      }
      this.isFetching = true;
      axios({
        url: "admin/products",
        method: "get",
        params: {
          page: this.page,
          search: this.saved_product_name
        },
        headers: store.getters.authorizationHeader
      })
        .then(({ data }) => {
          data.data.forEach(item => this.products.push(item));
          this.page++;
          this.totalPages = data.last_page;
        })
        .catch(error => {
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
    getMoreAsyncData: _.debounce(function() {
      this.getAsyncData(this.saved_product_name);
    }, 250),
    excludeChildren(id, children) {
      this.$nextTick(() => {
        if (!_.includes(this.category_ids, id)) {
          _.forEach(children, child => {
            let index = _.indexOf(this.category_ids, child.id);
            if (index > -1) this.category_ids.splice(index, 1);
          });
        }
      });
    },
    includeParent(id, parent_id) {
      this.$nextTick(() => {
        if (_.includes(this.category_ids, id)) {
          if (!_.includes(this.category_ids, parent_id))
            this.category_ids.push(parent_id);
        }
      });
    },
    setProductImageId(ids) {
      this.image_id = ids.length ? ids[0] : null;
    },
    setGalleryImageIds(ids) {
      this.image_ids = ids;
    },
    openFilesBrowser() {
      this.$fb.show({
        // ids: [this.image_id],
        multiple: false,
        // image_id: this.image_id,
        emit: "reference-selected",
        type: "image"
      });
    },
    setReferences(ref) {
      let i = _.findIndex(this.references, ["file_id", ref[0]]);
      if (i < 0) {
        let newReference = {
          name: `${this.code} ${this.name} - `,
          file_id: ref[0],
          coordinates: []
        };
        this.references.push(newReference);
      }
    },
    updateReference(index, eventData) {
      this.references[index].coordinates = eventData;
    },
    removeReference(event) {
      this.references.splice(event.index, 1);
      if (_.has(event, "id") && !_.isUndefined(event.id)) {
        store.dispatch("deleteReference", [event.id]);
      }
    }
  },
  mounted() {
    if (this.$route.name == "Create Product" && this.$route.query.id) {
      this.$refs["name-input"].focus();
    }
    if (this.$route.name == "Create Product" && !this.$route.query.id) {
      this.$refs["code-input"].focus();
    }
    store.dispatch("getProductColors");
  },
  beforeMount() {
    Events.$on("image-selected", this.setProductImageId);
    Events.$on("gallery-selected", this.setGalleryImageIds);
    Events.$on("reference-selected", this.setReferences);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id, fr: to.query.id })
        .then(res => {
          next(vm => {
            vm.categories = res.data.categories;
            if (res.data.product) {
              vm.name = to.query.id ? "" : res.data.product.name;
              vm.slug = to.query.id ? "" : res.data.product.slug;
              vm.savedSlug = to.query.id ? "" : res.data.product.slug;
              vm.product_id = to.query.id
                ? to.query.id
                : res.data.product.product_id;
              vm.image_id = to.query.id ? null : res.data.product.image_id;

              vm.code = to.query.id
                ? `${res.data.product.code}-${
                    res.data.product.variants
                      ? res.data.product.variants.length + 1
                      : 1
                  }`
                : res.data.product.code;
              vm.price_wholesale = res.data.product.price_wholesale;
              vm.price_partner = res.data.product.price_partner;
              vm.price_direct_sales = res.data.product.price_direct_sales;
              vm.length = res.data.product.length;
              vm.width = res.data.product.width;
              vm.height = res.data.product.height;
              vm.volume_packed = res.data.product.volume_packed;
              vm.parts = parseFloat(res.data.product.parts);
              vm.weight_net = res.data.product.weight_net;
              vm.weight_gross = res.data.product.weight_gross;
              vm.category_ids = _.map(res.data.product.categories, "id");

              vm.notBase = res.data.product.variants ? false : true;
              if (to.query.id) {
                vm.product_name = res.data.product.name;
              } else {
                vm.product_name = res.data.product.base
                  ? res.data.product.base.name
                  : "";
              }

              vm.image_ids = to.query.id
                ? []
                : _.map(res.data.product.images, "id");

              vm.references = res.data.product.references;
            }
          });
        });
    }
  }
};
</script>
